<template>
    <div> 
        <b-card no-body class="card-statistics"> 
        <b-card-body class="statistics-body" style="margin-top: -10px; margin-bottom: -5px;">
            <b-row>
                <b-col md="12">
                    <h6><small><b>Nilai Kontrak Berdasarkan Jenis Kegiatan</b></small></h6>
                </b-col>
            </b-row>
            <b-row> 
                <b-col  xl="4" sm="6" class="mb-2 mb-xl-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2" >
                            <b-avatar size="55" variant="primary">
                                <feather-icon size="24" icon="SettingsIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <b-card-text class="font-small- mb-0">
                                <small >Kegiatan {{ lodaData.jm_kegiatan[0].nm_kegiatan }}</small>   
                            </b-card-text>
                            <h5 class="font-weight-bolder mb-0">
                                <span  class="primary"><sup>Rp. </sup>{{ Rp(lodaData.jm_kegiatan[0].nilai_kontrak )}} <br> <small><sub> {{ lodaData.jm_kegiatan[0].jm }} Sub kegiatan </sub></small></span>   
                            </h5>
                           
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col  xl="4" sm="6" class="mb-2 mb-xl-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2" >
                            <b-avatar size="55" variant="info">
                                <feather-icon size="24" icon="UserCheckIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <b-card-text class="font-small- mb-0">
                                <small >Kegiatan {{ lodaData.jm_kegiatan[1].nm_kegiatan }}</small>   
                            </b-card-text>
                            <h5 class="font-weight-bolder mb-0">
                                <span  class="primary"><sup>Rp. </sup>{{ Rp(lodaData.jm_kegiatan[1].nilai_kontrak )}} <br> <small><sub> {{ lodaData.jm_kegiatan[1].jm }} Sub kegiatan </sub></small></span>   
                            </h5>
                           
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col  xl="4" sm="6" class="mb-2 mb-xl-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2" >
                            <b-avatar size="55" variant="warning">
                                <feather-icon size="24" icon="PackageIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <b-card-text class="font-small- mb-0">
                                <small >Kegiatan {{ lodaData.jm_kegiatan[2].nm_kegiatan }}</small>   
                            </b-card-text>
                            <h5 class="font-weight-bolder mb-0">
                                <span  class="primary"><sup>Rp. </sup>{{ Rp(lodaData.jm_kegiatan[2].nilai_kontrak )}} <br> <small><sub> {{ lodaData.jm_kegiatan[2].jm }} Sub kegiatan </sub></small></span>   
                            </h5>
                           
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row> 
        </b-card-body>
    </b-card>
    </div>
</template>

<script> 
    import {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
    } from 'bootstrap-vue'
import Base from '@/config/Mixins_base'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardText,
            BCardBody,
            BMedia,
            BAvatar,
            BMediaAside,
            BMediaBody,
        },
        props: {
            lodaData: { 

            },
        },
        mixins : [Base],
        data() {
            return {
            }
        }
    }
</script>