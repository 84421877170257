<template>
    <div>
        <b-row>
            <b-col>
                <!-- card-congratulations -->
                <b-card class="earnings-card   card-congratulations bg-warning">
                    <b-row style="margin-bottom: -10px;;">
                        <b-col cols="12">

                            <h5 class=" text-white"> <small>Total Ralisasi Anggaran <br></small> {{
                                lodaData.pencairan_bulanan.bulan }}
                            </h5>
                            <h1 class=" text-white">
                                <sup>Rp</sup> {{ Rp(lodaData.pencairan_bulanan.niminal) }}
                            </h1>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row style=" margin-top: -15px;">
            <b-col>
                <!-- card-congratulations -->
                <b-card class="earnings-card   card-congratulations-warning bg-primary">
                    <b-row style="margin-bottom: -10px;;">
                        <b-col cols="12">

                            <h5 class=" text-white"> <small>Total Pendapatan <br></small> {{
                                lodaData.pencairan_bulanan.bulan }}
                            </h5>
                            <h1 class=" text-white">
                                <sup>Rp</sup> {{ Rp(lodaData.tra_realisasi_pendapatan.anggaran) }}
                            </h1>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>


    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Base from '@/config/Mixins_base'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        BCardText,
        VueApexCharts,
    },
    mixins: [Base],
    props: {
        lodaData: {},
        data: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {}
    },
}
</script>