<template>
    <div> 
        <b-card no-body class="card-statistics"> 
        <b-card-body class="statistics-body" style="margin-top: -10px; margin-bottom: -5px;">
            <b-row> 
                <b-col   xl="3" sm="6" class="item.customClass">
                    <b-media no-body> 
                        <b-media-body class="my-auto">
                            <b-card-text class="font-small- mb-0">
                                <small>Total Anggaran Paket</small>   
                            </b-card-text>
                            <h5 class="font-weight-bolder mb-0">
                                <span class="text-primary"> <sup>Rp. </sup> {{ Rp(lodaData.tra_paket) }}</span>   
                            </h5>
                           
                        </b-media-body>
                    </b-media>
                </b-col>

                <b-col   xl="3" sm="6" class="item.customClass">
                    <b-media no-body> 
                        <b-media-body class="my-auto">
                            <b-card-text class="font-small- mb-0">
                                <small>Total Anggaran Kontrak</small>   
                            </b-card-text>
                            <h5 class="font-weight-bolder mb-0">
                                <span class="text-primary"> <sup>Rp. </sup> {{ Rp(lodaData.tra_peket_kontrak) }}</span>   
                            </h5>
                           
                        </b-media-body>
                    </b-media>
                </b-col>

                <b-col   xl="3" sm="6" class="item.customClass">
                    <b-media no-body> 
                        <b-media-body class="my-auto">
                            <b-card-text class="font-small- mb-0">
                                <small>Total Serapan Angaran</small>   
                            </b-card-text>
                            <h5 class="font-weight-bolder mb-0">
                                <span class="text-info"> <sup>Rp. </sup> {{ Rp(lodaData.tra_pencairan_dana) }}</span>   
                            </h5>
                           
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col   xl="3" sm="6" class="item.customClass">
                    <b-media no-body> 
                        <b-media-body class="my-auto">
                            <b-card-text class="font-small- mb-0">
                                <small>Total Sisa Angaran</small>   
                            </b-card-text>
                            <h5 class="font-weight-bolder mb-0">
                                <span class="text-warning"> <sup>Rp. </sup> {{ Rp( parseInt(lodaData.tra_peket_kontrak) - parseInt(lodaData.tra_pencairan_dana) ) }}</span>   
                            </h5>
                           
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row> 
        </b-card-body>
    </b-card>
    </div>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
    } from 'bootstrap-vue' 
import Base from '@/config/Mixins_base'
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardText,
            BCardBody,
            BMedia,
            BAvatar,
            BMediaAside,
            BMediaBody,
        },
        mixins :[Base],
        props: {
            lodaData : {}
        },
        data() {
            return {
            }
        }
    }
</script>