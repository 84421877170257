<template>
    <div>
        <!-- match-height -->
        <b-row class="match-height">
            <b-col md="4">
                <!-- <b-img fluid :src="require('@/assets/images/pages/coming-soon.svg')" alt="Login V2" /> -->
                <ProsentaseSerapanAnggaran :lodaData=lodaData></ProsentaseSerapanAnggaran>
            </b-col>
            <b-col md="8">
                <statustikAnggaranVue :lodaData=lodaData></statustikAnggaranVue> 
                <statistik style="margin-top: -10px;;" :lodaData=lodaData></statistik>
            </b-col> 
        </b-row>
        <b-row class=" ">
            <b-col md="4">
                <Kegiatan :lodaData = lodaData></Kegiatan>
            </b-col>
            <b-col md="8">
                <b-card>
                    <tabel-serapan :items = opd></tabel-serapan>
                </b-card>
            </b-col>

        </b-row>


    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BImg,
        bCard,
        bMediaAside,
        BMedia,
        BAlert,
        BMediaBody,
        BCardText,
        BCardTitle,
    } from 'bootstrap-vue'
    import profilOpd from './component/profilOpd.vue'
    import Statistik from './component/statistik.vue'
    import KategoriKegiatan from './component/kategoriKegiatan.vue'
    import ProsentaseSerapanAnggaran from './component/prosentaseSerapanAnggaran.vue'
    import Kegiatan from './component/Kegiatan.vue'
    import TabelSerapan from './component/TabelSerapan.vue'
    import axios from '@/config/Axios'
    import Base from '@/config/Mixins_base' 
import statustikAnggaranVue from './component/statustikAnggaran.vue'
    export default {
        components: {
            profilOpd,
            BRow,
            BCol,
            Statistik,
            BCard,
            BAvatar,
            BImg,
            bMediaAside,
            BMedia,
            BAlert,
            BMediaBody,
            BCardText,
            KategoriKegiatan,
            ProsentaseSerapanAnggaran,
            BCardTitle,
            Kegiatan,
            TabelSerapan,statustikAnggaranVue
        },
        data() {
            return { 
                lodaData: {},
                opd: []
            }
        },
        mixins: [Base],
        mounted() {
            this.load_data();
            this.detail_opd();
          
        },
        methods: {
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/home/load_data',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.lodaData = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async detail_opd() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/home/detail_opd',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.opd = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }

    }
</script>

<style>

</style>