<template>
    <div>
        Pemkab
    </div>
</template>

<script>
    import {
        BRow,
        BCol
    } from 'bootstrap-vue'

    export default {
        comments: {
            BRow,
            BCol
        }

    }
</script>

<style>

</style>