<template>
  <div>
    <!-- <small class="mr-1" @click="loadDasboard('1')">admin opd</small> || 
    <small @click="loadDasboard('0')">Pemkab Kabupaten Kota</small> {{ admin }} -->
    <admin_opd v-if="admin == '1'"></admin_opd>
    <admin_pemkab v-if="admin == '0'"></admin_pemkab>
  </div>
</template>

<script>
import admin_opd from './admin_opd/index.vue';
import admin_pemkab from './pemkab/index.vue'
import {
  BRow,
  BCol
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    admin_opd, admin_pemkab
  },


  data() {
    return {
      admin: 1
    }
  },
  mounted() {

  },
  methods: {
    loadDasboard(st) {
      this.admin = st;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
