<template>
    <div> 
        <b-row class="match-height">
            <b-col md="12">
                <b-card class="bg-light-primary"  style="margin-top: -15px;">
                    <b-row>
                        <b-col>
                            <h4 class="">Pengadaan</h4>
                        </b-col>
                    </b-row>
                    <b-row class="text-center ">
                        <b-col md="4">
                            <small class="">Paket</small>
                            <h2 class=""><strong>{{ lodaData.jm_kegiatan_pengadaan.jm_paket }}</strong></h2>
                        </b-col>
                        <b-col md="4"><small class="">Realisasi</small>
                            <h2 class=""><strong>{{ lodaData.jm_kegiatan_pengadaan.jm_kontrak }}</strong></h2>
                        </b-col>
                        <b-col md="4"><small class="">Sisa</small>
                            <h2 class=""><strong>{{ parseInt(lodaData.jm_kegiatan_pengadaan.jm_paket ) - parseInt(lodaData.jm_kegiatan_pengadaan.jm_kontrak) }}</strong></h2>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="12">
                <b-card class="bg-light-primary"  style="margin-top: -15px;">
                    <b-row>
                        <b-col>
                            <h4 class="">Kegiatan Fisik</h4>
                        </b-col>
                    </b-row>
                    <b-row class="text-center ">
                        <b-col md="4">
                            <small class="">Paket</small>
                            <h2 class=""><strong>{{ lodaData.jm_kegiatan_fisik.jm_paket }}</strong></h2>
                        </b-col>
                        <b-col md="4"><small class="">Realisasi</small>
                            <h2 class=""><strong>{{ lodaData.jm_kegiatan_fisik.jm_kontrak }}</strong></h2>
                        </b-col>
                        <b-col md="4"><small class="">Sisa</small>
                            <h2 class=""><strong>{{ parseInt(lodaData.jm_kegiatan_fisik.jm_paket ) - parseInt(lodaData.jm_kegiatan_fisik.jm_kontrak) }}</strong></h2>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="12">
                <b-card class="bg-light-primary "  style="margin-top: -15px;">
                    <b-row>
                        <b-col>
                            <h4 class="">Kegiatan Konsultasi</h4>
                        </b-col>
                    </b-row>
                    <b-row class="text-center ">
                        <b-col md="4">
                            <small class="">Paket</small>
                            <h2 class=""><strong>{{ lodaData.jm_kegiatan_konsultasi.jm_paket }}</strong></h2>
                        </b-col>
                        <b-col md="4"><small class="">Realisasi</small>
                            <h2 class=""><strong>{{ lodaData.jm_kegiatan_konsultasi.jm_kontrak }}</strong></h2>
                        </b-col>
                        <b-col md="4"><small class="">Sisa</small>
                            <h2 class=""><strong>{{ parseInt(lodaData.jm_kegiatan_konsultasi.jm_paket ) - parseInt(lodaData.jm_kegiatan_konsultasi.jm_kontrak) }}</strong></h2>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
             
        </b-row>
    </div>
</template>

<script>
    import {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        BCardText,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import {
        $themeColors
    } from '@themeConfig'

    const $earningsStrokeColor2 = '#28c76f66'
    const $earningsStrokeColor3 = '#28c76f33'
    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BCardTitle,
            BCardText,
            VueApexCharts,
        },
        props: {
            lodaData : {} 
        },
        data() {
            return {
                series: [70, 36],
                earningsChart: {
                    chartOptions: {
                        chart: {
                            type: 'donut',
                            toolbar: {
                                show: false,
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        legend: {
                            show: false
                        },
                        comparedResult: [2, -3, 8],

                        stroke: {
                            width: 0
                        },
                        colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
                        grid: {
                            padding: {
                                right: -20,
                                bottom: -8,
                                left: -20,
                            },
                        },
                        plotOptions: {
                            pie: {
                                startAngle: -10,
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            offsetY: 15,
                                        },
                                        value: {
                                            offsetY: -15,
                                            formatter(val) {
                                                // eslint-disable-next-line radix
                                                return `${parseInt(val)}%`
                                            },
                                        },
                                        total: {
                                            show: true,
                                            offsetY: 15,
                                            label: 'App',
                                            formatter() {
                                                return '70%'
                                            },
                                        },
                                    },
                                },
                            },
                        },

                    },
                },
            }
        },
    }
</script>